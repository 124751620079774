import React from 'react'
import { Layout } from '../components/LayoutLanding'
import { Links } from '../components/Links'
import { LandingTopbar } from '../components/LandingTopbar'
import { SEO } from '../components/SEO'

const Security = () => (
  <Layout>
    <SEO
      title="Vanna | Segurança"
      tags={[
        'Cobranças simples',
        'Cobranças recorrentes',
        'Segurança',
        'Pix',
        'Rapidez',
        'Receba em segundos',
      ]}
      canonical="https://vanna.app/seguranca/"
    />
    <LandingTopbar />
    <Links />
    <section className="my-16 px-5 md:px-8 lg:px-16">
      <div className="md:max-w-3xl md:mx-auto md:text-center mb-20">
        <h1 className="text-gray-900 text-3xl leading-9 font-heading font-medium lg:text-4xl">
          Segurança.
        </h1>
        <p className="my-4 text-gray-800 leading-6 sm:text-xl lg:text-lg xl:text-xl">
          Nossa área de segurança é levada muito a sério aqui na Vanna. Nossos
          clientes confiam muito em nós com dados pessoais e transacionais,
          portanto usamos as melhores práticas recomendadas para mantê-los
          protegidos.
        </p>
        <div className="flex justify-center">
          <img className="h-12 w-auto" src="/images/logo-s.png" alt="Logo" />
        </div>
      </div>
      <h2 className="my-4 font-bold">Backups e Recuperação de Dados</h2>
      <p>
        Backups são realizados de hora em hora e temos redundância nos dados
        salvos. Nossos processos são testados e em caso de perda de dados, em
        menos de 1 hora o sistema está de pé com todos os dados ativados. Seus
        dados podem ser exportados via .csv, entre em contato para saber mais.
      </p>
      <h2 className="my-4 font-bold">Disponibilidade</h2>
      <p>
        Nosso time está 100% focado em oferecer a melhor experiência da sua
        vida, então prezamos pela disponibilidade de nossa plataforma e serviços
        com infraestrutura redundante e com tolerância a falhas. Caso nossa
        plataforma e APIs sofrerem falhas, nossos clientes serão avisados e você
        pode ver o status{' '}
        <a
          href="https://stats.uptimerobot.com/l8gqKuoxlw"
          target="_blank"
          style={{ textDecoration: 'underline' }}
          rel="noopener noreferrer"
        >
          clicando aqui
        </a>
        .
      </p>
      <h2 className="my-4 font-bold">Criptografia</h2>
      <p>
        Ao usar nosso produto, todos os seus dados são trafegados via HTTPS.
        Isso significa que seus dados (por exemplo, senhas) são criptografados
        para que não possam ser interceptados por hackers. Nosso banco de dados
        principal e todos os backups são criptografados. Toda a comunicação
        entre os datacenters é feita por SSL.
      </p>
      <h2 className="my-4 font-bold">Hospedagem & Provedores de Serviços</h2>
      <p>
        A segurança dos nossos serviços é essencial para a continuidade de nosso
        negócio, por isso escolhemos nossos fornecedores com muito afinco. Todos
        são certificados pelo SOC 2. Os serviços da Vanna estão hospedados na
        AWS. Você pode saber mais sobre a segurança da Amazon aqui:
        <a
          href="https://aws.amazon.com/security"
          target="_blank"
          style={{ textDecoration: 'underline' }}
          rel="noopener noreferrer"
        >
          https://aws.amazon.com/security
        </a>
        .
      </p>
      <h2 className="my-4 font-bold">Autenticação</h2>
      <p>
        A autenticação para acesso a plataforma Vanna é realizada por email e
        senha. Você também pode habilitar 2FA (OTP) e nunca mandamos o código
        via SMS. Lembre-se de sempre utilizar uma senha extremamente forte.
      </p>
      <h2 className="my-4 font-bold">
        Acesso aos dados do cliente e políticas de auditoria
      </h2>
      <p>
        As políticas de acesso a dados do cliente são extremamente rígidas e
        lembre-se que não pedimos senha nem acesso a sua conta. Podemos apenas
        pedir confirmações de sua identidade para entender qual o problema
        enfrentado.
      </p>
      <h2 className="my-4 font-bold">Melhoria contínua</h2>
      <p>
        Novas funcionalidades da plataforma e processos internos são revisados e
        avaliados quanto ao impacto de segurança antes de serem liberados para
        produção. Nos esforçamos ao máximo para monitorar e aprimorar
        continuamente nossas práticas de segurança e ao mesmo tempo entregar
        funcionalidades que dão resultado.
      </p>
      <p className="mt-4">Tem alguma dúvida? Entre em contato!</p>
    </section>
  </Layout>
)

export default Security
